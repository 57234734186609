import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { cond, always, prop, T } from 'ramda';
import { useHistory } from 'react-router-dom';
import { isTrue } from 'ramda-adjunct';
import { Collapse } from '../../../components';
import { QTagsQuery } from '../../../_graphql/queries/documents/QTags';
import TagsTable from '../../docs/components/tag/TagsTable';
import { deleteTag } from '../../helpers/deleteTag';
import { SCOPE_OFFER } from '../../../_CONST';
import useFetchQuery from '../../../hooks/useFetchQuery';
import Loading from '../../../components/Loading';

const TagsSection = ({
  offerId,
  userCanEditOffer
}) => {
  const history = useHistory();
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const baseUrlOffer = `/offre/${offerId}`;
  const onEdit = (tagId) => history.push(`${baseUrlOffer}/tags/${tagId}/edition`);
  const onPreview = (tagId) => history.push(`${baseUrlOffer}/tags/${tagId}/preview`);
  const onCreate = () => history.push(`${baseUrlOffer}/edition/tags/creer`);
  const getLink = (tag) => cond([
    [isTrue, always(`/offre/${offerId}/tags/${prop('id', tag)}/edition`)],
    [T, always(`/offre/${offerId}/tags/${prop('id', tag)}/preview`)]
  ])(userCanEditOffer);

  const { tags, isLoading, reFetch } = useFetchQuery({
    query: QTagsQuery,
    args: { offerId, scope: SCOPE_OFFER },
    dataProp: 'tags',
    defaultData: null,
    skip: !collapseIsOpen || hasFetched
  });
  useEffect(() => {
    if (collapseIsOpen && !hasFetched && !isLoading && tags) {
      setHasFetched(true);
    }
  }, [collapseIsOpen, hasFetched, isLoading, tags]);

  return (
    <Collapse
      className="section-tag"
      collapseIsOpen={collapseIsOpen}
      setCollapseIsOpen={setCollapseIsOpen}
      title="Tags de l'offre"
      actions={userCanEditOffer && (
        <Button
          onClick={onCreate}
          icon={<PlusOutlined />}
        >
          Créer un tag
        </Button>
      )}
    >
      {isLoading ? <Loading />
        : (
          <TagsTable
            tags={tags || []}
            userCanEditOffer={userCanEditOffer}
            onClickEdit={onEdit}
            onClickPreview={onPreview}
            onClickDelete={deleteTag}
            getLink={getLink}
            reFetch={reFetch}
          />
        )
      }
    </Collapse>

  );
};

export default TagsSection;
