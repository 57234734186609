import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

const QTagQuery = graphql`
  query QTagQuery($tagId: ID!) {
    tag(tagId: $tagId) {
      id
      teamId
      name
      color
      itemType
      itemsIds
      scope
      offerId
      rules {
        itemId
        rules {
          action
          conditions {
            condition
            variable
            value
            operator
          }
        }
      }
      items {
        _id
        name
      }
    }
  }
`;

const QTag = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QTagQuery}
    caching={false}
    fetchPolicy="network-only"
  >
    {populateChildren('tag')(children, childDataProp)}
  </Query>
);

export default QTag;
