import { FontSizeOutlined } from '@ant-design/icons';
import React from 'react';
import classNames from 'classnames';
import withUser from '../../../../../../withUser';
import VariableContainer from './VariableContainer';

const JSONVariable = ({ userIsAdmin, label, key, value, onChange, onChangeIsDefaultValue, isDefaultValue }) => (
  <VariableContainer
    label={label}
    icon={<FontSizeOutlined />}
    onChangeIsDefaultValue={onChangeIsDefaultValue}
    isDefaultValue={isDefaultValue}
    value={JSON.parse(value)}
  >
    <textarea
      id={key}
      name={key}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      cols="90"
      rows="20"
      disabled={!userIsAdmin}
      className={classNames('w-full', { 'bg-flexibranche-wildsand cursor-not-allowed text-gray-300': !userIsAdmin })}
    />
  </VariableContainer>
);

export default withUser(JSONVariable);
