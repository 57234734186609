import React, { useState } from 'react';
import { isNil, propEq } from 'ramda';
import { Button } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import ModalCreateVersion from '../pages/offer/editOffer/ModalCreateVersion';

const useCreateVersion = () => {
  const [showModal, setShowModal] = useState(false);

  return {
    showModal,
    setShowModal
  };
};

const CreateVersion = ({
  versionId,
  userCanCreateVersion,
  redirect,
  version
}) => {
  const {
    showModal,
    setShowModal
  } = useCreateVersion();

  if (!userCanCreateVersion || isNil(version) || !propEq('status', 'release', version)) {
    return null;
  }
  const {
    isLastMajor,
    isLastMinor
  } = version;
  const canCreateVersion = isLastMinor || (isLastMajor && isLastMinor);

  return (
    <>
      <ModalCreateVersion
        versionId={versionId}
        redirect={redirect}
        isLastMinor={isLastMinor}
        isLastMajor={isLastMajor}
        visible={showModal}
        onHide={() => setShowModal(false)}
      />

      {canCreateVersion && (
        <Button
          size="large"
          type="primary"
          icon={<AppstoreAddOutlined />}
          onClick={() => setShowModal(true)}
        >
          Créer une nouvelle version
        </Button>
      )}
    </>
  );
};

export default CreateVersion;
