import { Alert, Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { compose, equals, find, isEmpty, map, path, prop, propEq, propOr } from 'ramda';
import { notEqual } from 'ramda-adjunct';
import React, { useState } from 'react';
import { useDocumentContext } from '../../../../context/Document';
import WidgetsForm from './documentFormTabs/createTab/WidgetsForm';

const DocumentContents = ({
  type,
  content,
  addWidget,
  addRowWidget,
  removeWidget,
  updateWidget,
  addWidgetRule,
  removeWidgetRule,
  updateWidgetRule,
  getRulesAreValid,
  getConditionsAreValid,
  dataItemPrefix,
  moveRowWidget,
  sectionIndex,
  title,
  hasSectionBreakPage,
  addManualBreakPageRule,
  removeManualBreakPageRule,
  updateManualBreakPageRule,
  setIsOpenCreateWidgetModal,
  userIsAdmin,
  isEdit
}) => {
  const {
    scope,
    setWidgetIdEditing,
    setRowIndexEditing,
    updatePaddingRow,
    loading,
    updateManualBreakPage,
    previewLoading,
    values,
    globalVariables,
    widgets,
    setWidgetCreated,
    setDuplicateWidgetPlacement,
    setIsOpenCreateGlobalVariableModal
  } = useDocumentContext();

  const getWidgetVariables = widgetId => compose(
    propOr([], 'variables'),
    find(propEq('id', widgetId))
  )(widgets);

  const globalVariablesNames = map(prop('name'), globalVariables);
  const [isDynamicTableInSection, setIsDynamicTableInSection] = useState(false);

  const displayAddBtn = notEqual('widgets', type) && isEmpty(path(['pages', sectionIndex, type], values));

  return (
    <div className={classNames('section-container', { 'mt-2 mb-2': equals('widgets', type) })}>
      <div className="text-base text-flexibranche-blue font-medium">{title}</div>
      {displayAddBtn ? (
        userIsAdmin && (
          <Tooltip title={`Ajouter ${equals('header', type) ? 'une en-tête' : 'un pied de page'}`}>
            <Button
              size="small"
              onClick={addWidget(sectionIndex, type)}
              disabled={loading || previewLoading}
              className={classNames('rounded-full ml-3 mt-3', equals('header', type) ? 'add-header-btn' : 'add-footer-btn')}
            >
              +
            </Button>
          </Tooltip>
        )
      ) : (
        <div className="scrollbar max-h-450px pt-3 overflow-y-auto">
          {isEdit && userIsAdmin && isDynamicTableInSection && !hasSectionBreakPage && (
            <Alert
              type="warning"
              showIcon
              closable
              description="Le tableau dynamique peut dépasser de la page, pensez à activer le saut de page automatique."
            />
          )}
          <WidgetsForm
            widgetsList={widgets}
            widgets={content}
            setWidgetIdEditing={setWidgetIdEditing}
            setRowIndexEditing={setRowIndexEditing}
            type={equals('widgets', type) ? 'content' : type}
            scope={scope}
            getRulesAreValid={getRulesAreValid}
            getConditionsAreValid={getConditionsAreValid}
            getWidgetVariables={getWidgetVariables}
            globalVariables={globalVariables}
            itemPathPrefix={[...dataItemPrefix, 'pages', sectionIndex, type]}
            addRowWidget={addRowWidget(sectionIndex, type)}
            addWidget={addWidget(sectionIndex, type)}
            updateManualBreakPage={updateManualBreakPage(sectionIndex)}
            updatePaddingRow={updatePaddingRow(sectionIndex, type)}
            removeWidget={removeWidget(sectionIndex, type)}
            moveRowWidget={moveRowWidget(sectionIndex, type)}
            updateWidget={updateWidget(globalVariablesNames, sectionIndex, type)}
            addWidgetRule={addWidgetRule(sectionIndex, type)}
            removeWidgetRule={removeWidgetRule(sectionIndex, type)}
            updateWidgetRule={updateWidgetRule(sectionIndex, type)}
            disabled={loading || previewLoading}
            setIsDynamicTableInSection={setIsDynamicTableInSection}
            hasSectionBreakPage={hasSectionBreakPage}
            addManualBreakPageRule={addManualBreakPageRule(sectionIndex)}
            removeManualBreakPageRule={removeManualBreakPageRule(sectionIndex)}
            updateManualBreakPageRule={updateManualBreakPageRule(sectionIndex)}
            setIsOpenCreateWidgetModal={setIsOpenCreateWidgetModal}
            setDuplicateWidgetPlacement={setDuplicateWidgetPlacement}
            setWidgetCreated={setWidgetCreated}
            userIsAdmin={userIsAdmin}
            setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
          />
          {userIsAdmin && (
            <Tooltip title="Ajouter une ligne">
              <Button
                size="small"
                onClick={addWidget(sectionIndex, type)}
                disabled={loading || previewLoading}
                className="rounded-full ml-3"
              >
                +
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentContents;
