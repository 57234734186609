import { useEffect, useState } from 'react';
import { compose, pick, propEq, propOr, reduce } from 'ramda';
import { isNotNil, isNotNilOrEmpty } from 'ramda-adjunct';
import useFetchQuery from '../../../../hooks/useFetchQuery';
import { QOfferMetadataQuery } from '../../../../_graphql/queries/offers/QOfferMetadata';

const useOfferMetadata = (offerId, bundleTag) => {
  const [metadata, setMetadata] = useState({});
  const {
    offerMetadata,
    isLoading,
    reFetch
  } = useFetchQuery({
    defaultData: {},
    args: {
      offerId,
      bundleTag
    },
    query: QOfferMetadataQuery,
    dataProp: 'offerMetadata'
  });

  useEffect(() => {
    if (!isLoading && propEq('ok', true, offerMetadata)) {
      const getValue = ({
        type,
        value
      }, options) => {
        if (isNotNil(type)) {
          switch (type) {
          case 'number':
            return Number(value);
          case 'boolean':
            return Math.floor(Math.random() * 2) % 2 === 0;
          case 'JSON':
            return JSON.parse(value);
          default:
            return value;
          }
        }

        if (isNotNilOrEmpty(options)) {
          return propOr(false, 'value', options);
        }

        return '';
      };
      compose(
        setMetadata,
        reduce((acc, v) => {
          const {
            key,
            options,
            default: defaultValue
          } = pick(['key', 'options', 'default'], v);
          const value = getValue(defaultValue, options);

          return {
            ...acc,
            [key]: value
          };
        }, {}),
        propOr([], 'data')
      )(offerMetadata);
    }
  }, [isLoading, offerMetadata]);

  return {
    metadata: JSON.stringify(metadata, null, 2), isLoading, reFetch
  };
};

export default useOfferMetadata;
