import { useState } from 'react';
import { compose, cond, equals, always, prop, propEq, both } from 'ramda';
import { message } from 'antd';
import UpdateVersionStatusMutation from '../_graphql/mutations/versions/UpdateVersionStatusMutation';
import { errorMessage } from '../utils/messageMutation';

const typeToLabel = compose(
  cond([
    [equals('offer'), always('L\'offre est passée')],
    [equals('widget'), always('Le widget est passé')],
    [equals('document'), always('Le document est passé')],
    [equals('style'), always('Le style est passé')]
  ]),
  prop('itemType')
);

const useStatusActions = ({ version, reFetchOffer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateStatus = status => () => {
    setIsLoading(true);

    UpdateVersionStatusMutation({
      versionId: prop('versionId', version), status, itemId: prop('itemId', version)
    }, (ok, error) => {
      if (ok && !error) {
        message.success(`${typeToLabel(version)} en mode ${status === 'review' ? 'revue' : 'édition'}.`);
        reFetchOffer && reFetchOffer();
      } else {
        errorMessage();
      }
      setIsLoading(false);
    });
  };

  const isDraft = propEq('status', 'draft');
  const isReview = propEq('status', 'review');
  const isAnOffer = propEq('itemType', 'offer');
  const isAnOfferInDraft = both(isDraft, isAnOffer);
  const isAnOfferInReview = both(isReview, isAnOffer);

  return {
    updateStatus,
    isLoading,
    canSendToReview: isAnOfferInDraft(version),
    canSendToDraft: isAnOfferInReview(version)
  };
};

export default useStatusActions;
