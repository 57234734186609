import { map } from 'ramda';
import { QColorsQuery } from '../_graphql/queries/documents/QColors';
import useFetchQuery from './useFetchQuery';

const useColors = () => {
  const { colors: teamColors, isLoading } = useFetchQuery({
    query: QColorsQuery,
    dataProp: 'colors',
    defaultData: []
  });

  const colors = map(({ name, value }) => ({ color: value, title: name }))(teamColors);

  return { colors, isLoading };
};

export default useColors;
