import { DeleteOutlined, DownOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Select, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { assoc, find, groupBy, map, path, pick, prop, propEq } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Section, Title } from '../../../components';
import SwitchByExisting from '../../../components/SwitchByExisting';
import useFrame from '../../../hooks/useFrame';
import { UserContext } from '../../../withUser';
import { SCOPE_OFFER } from '../../../_CONST';
import { QTagsQuery } from '../../../_graphql/queries/documents/QTags';
import { getVersionToString } from '../../helpers/getVersionToString';
import { sortByNameCaseInsensitive } from '../../helpers/sortByNameCaseInsensitive';
import Metadata from '../Metadata';
import { checkUserRole } from '../../helpers/checkUserRole';
import { ROLES } from '../../../lib/rights';
import useFetchQuery from '../../../hooks/useFetchQuery';
import Loading from '../../../components/Loading';
import { QOffersQuery } from '../../../_graphql/queries/QOffers';

const FrameForm = ({ allFrames, frame, onEdit, setRefetch }) => {
  const { userTeam, user } = useContext(UserContext);
  const userCanEditFrame = checkUserRole(user, userTeam, [ROLES.admin, ROLES.qa]);
  const {
    values,
    loading,
    setValues,
    onSave,
    buttonIsDisabled,
    setState,
    onDelete,
    onUpdate,
    setDefaultValues,
    onSelectExternalFrame,
    onClearFrame,
    filterOptionFrame,
    selectedExternalFrame
  } = useFrame({ frame });
  const [isCopyFrame, setIsCopyFrame] = useState(false);
  const [fromFrameId, setFromFrameId] = useState(null);

  const { tags, isLoading } = useFetchQuery({
    query: QTagsQuery,
    args: { offerId: prop('offerId', values), scope: SCOPE_OFFER },
    dataProp: 'tags',
    defaultData: [],
    skip: onEdit
  });
  const { offers, isLoading: isLoadingOffers } = useFetchQuery({
    query: QOffersQuery,
    args: {},
    dataProp: 'offers',
    defaultData: [],
    skip: onEdit
  });

  useEffect(() => {
    if (!isCopyFrame) setFromFrameId(null);
  }, [isCopyFrame]);

  useEffect(() => {
    if (allFrames) {
      const infoSelected = find(propEq('id', fromFrameId))(allFrames);
      if (isNotNilOrEmpty(infoSelected)) {
        const newInfo = pick(['bundleTag', 'description', 'metadata', 'name', 'offerId', 'offerName', 'version'])(infoSelected);
        setState(assoc('values', { ...values, ...newInfo }));
      } else {
        setState(assoc('values', setDefaultValues()));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromFrameId]);

  const title = onEdit ? 'Informations de ma trame' : 'Création d\'une trame';
  const hadComparisons = isNotNilOrEmpty(prop('comparisons', values));
  const isEditable = !prop('isReferent', frame) && !hadComparisons;
  const referentCannotChange = !userCanEditFrame || !isEditable;
  const userData = path(['who', 'creationData', 'extra'], values);

  if (isLoading || isLoadingOffers) return <Loading />;

  const offersSortedByName = sortByNameCaseInsensitive('offerName')(offers);

  const groupedOffers = values(groupBy(prop('offerName'), offersSortedByName));

  const onSelectOffer = (offerId) => {
    const offerSelected = find(propEq('offerId', offerId))(offersSortedByName);
    const { offerName, version } = offerSelected;
    setState(assoc('values', {
      ...values, offerName, offerId, version: getVersionToString(version), bundleTag: undefined, metadata: ''
    }));
  };

  return (
    <>
      <Title title={title} backTo="/trames" />
      <Section title={!onEdit && 'Ma trame'}>
        {isNotNilOrEmpty(allFrames) && (
          <SwitchByExisting
            checked={isCopyFrame}
            onChange={checked => setIsCopyFrame(checked)}
            onClick={() => setIsCopyFrame(!isCopyFrame)}
            label="Créer à partir d'une trame existante"
            fromValue={fromFrameId}
            onSelect={v => setFromFrameId(v)}
            placeholder="Trame à dupliquer"
            options={map(({ name, id }) => (
              <Select.Option
                key={id}
                value={id}
                title={name}
              >
                {name}
              </Select.Option>
            ))(allFrames)}
          />
        )}
        <div className="space-y-4 mt-4">
          <div className="inline-flex w-full justify-center items-center space-x-6">
            {onEdit && <p className="w-24">Libellé</p>}
            <Input
              placeholder="Libellé"
              value={prop('name', values)}
              disabled={!isEditable}
              onChange={(e) => setValues('name', path(['target', 'value'], e))}
            />
          </div>
          <div className="inline-flex w-full justify-center items-center mt-4 space-x-6">
            {onEdit && <p className="w-24">Description</p>}
            <TextArea
              placeholder="Description"
              rows={4}
              value={prop('description', values)}
              disabled={!isEditable}
              onChange={(e) => setValues('description', path(['target', 'value'], e))}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          {onEdit ? (
            <>
              <div className="space-x-4 inline-flex items-center">
                <p className="w-24">Offre</p>
                <Input
                  className="input-offer !w-72"
                  disabled
                  value={`${prop('offerName', values)} - ${prop('version', values)}`}
                />
                <p className="w-28 pl-12">Version</p>
                <Input
                  className="input-version !w-20"
                  value={prop('version', values)}
                  disabled
                />
                <p className="w-28 pl-12">Tag</p>
                <Input
                  className="input-tag !w-72"
                  value={prop('bundleTag', values)}
                  disabled
                />
              </div>
              <div className="mt-4 space-x-4 inline-flex items-center">
                <p className="w-24">Créée par</p>
                <Input
                  className="input-user !w-72"
                  disabled
                  value={`${prop('firstname', userData)} ${prop('lastname', userData)}`}
                />
                <p className="pl-12">Date de {prop('isReferent', frame) ? 'référence' : 'création'}</p>
                <Input
                  className="input-creation-date !w-72"
                  value={moment(path(['dates', 'lastUpdate'], values)).format('DD/MM/YYYY HH:mm:ss')}
                  disabled
                />
              </div>
            </>
          ) : (
            <div className="space-x-4 flex items-end">
              <div>
                <p className="text-flexibranche-lightblue">{prop('offerName', values) || 'Nom de mon offre'}</p>
                <Select
                  className="select-offer w-300px"
                  placeholder="Sélectionner une offre"
                  value={prop('offerId', values)}
                  onSelect={onSelectOffer}
                  suffixIcon={<DownOutlined className="!text-flexidocs-turquoise"/>}
                  showSearch
                  disabled={onEdit}
                >
                  {map((offers) => (
                    <Select.OptGroup key={path([0, 'offerName'], offers)} label={path([0, 'offerName'], offers)}>
                      {map(({ offerId, version }) => (
                        <Select.Option
                          key={offerId}
                          value={offerId}
                        >
                          {getVersionToString(version)}
                        </Select.Option>
                      ))(offers)}
                    </Select.OptGroup>
                  ))(groupedOffers)}
                </Select>
              </div>
              <Select
                className="select-tag w-300px"
                placeholder="Sélectionner un tag"
                value={prop('bundleTag', values)}
                onSelect={(v) => {
                  setValues('bundleTag', v);
                  onClearFrame();
                }}
                suffixIcon={<DownOutlined className={classNames({ '!text-flexidocs-turquoise': prop('offerId', values) })}/>}

                disabled={!prop('offerId', values)}
              >
                {sortByNameCaseInsensitive('name')(tags).map(
                  ({ name, id, color }) => (
                    <Select.Option key={id} value={name}>
                      {/* eslint-disable-next-line no-restricted-syntax */}
                      <span style={{ color }}>{name}</span>
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          )}
        </div>
        {onEdit ? (
          <div className="inline-flex w-full justify-center items-center mt-4 space-x-6">
            <p className="w-24">Metadata</p>
            <TextArea
              placeholder="Metadata"
              rows={4}
              value={prop('metadata', values)}
              disabled={!isEditable}
              onChange={(e) => setValues('metadata', path(['target', 'value'], e))}
            />
          </div>
        ) : (
          prop('offerId', values) && prop('bundleTag', values) && (
            <Metadata
              values={values}
              setValues={setValues}
              onSelectExternalFrame={onSelectExternalFrame}
              onClearFrame={onClearFrame}
              filterOptionFrame={filterOptionFrame}
              selectedExternalFrame={selectedExternalFrame}
            />
          )
        )}
        {onEdit ? (
          <div className="flex flex-col items-center space-y-4 mt-4">
            <div>
              <Switch
                size="small"
                checked={prop('isReferent', values)}
                onChange={(v) => setValues('isReferent', v)}
                disabled={hadComparisons}
              />
              <span
                className={`inline-block align-middle ml-2 ${referentCannotChange ? '' : 'cursor-pointer'}`}
                onClick={() => !referentCannotChange && setValues('isReferent', !prop('isReferent', values))}
              >
                Informations référentes
              </span>
            </div>
            {userCanEditFrame && (
              <div>
                <Popconfirm
                  title="Êtes-vous sûr de vouloir supprimer cette génération ?"
                  onConfirm={() => onDelete([prop('id', values)])}
                >
                  <Button
                    type="danger"
                    loading={loading}
                    icon={<DeleteOutlined className="text-[20px]" />}
                    className="btn-remove-frame !h-45px text-sm mr-5"
                  >
                    Supprimer
                  </Button>
                </Popconfirm>
                {!hadComparisons && (
                  <Button
                    type="primary"
                    disabled={buttonIsDisabled}
                    loading={loading}
                    onClick={() => onUpdate(setRefetch)}
                    icon={<ThunderboltOutlined className="text-[24px]" />}
                    className="btn-edit-frame !h-45px text-sm"
                  >
                    <span className="font-semibold">Enregistrer</span>
                  </Button>
                )}
              </div>
            )}
          </div>
        ) : (
          <Button
            loading={loading}
            disabled={buttonIsDisabled}
            onClick={onSave}
            type="primary"
            size="large"
            className="btn-create-frame block mt-6"
            icon={<ThunderboltOutlined />}
          >
            Créer une trame
          </Button>
        )}
      </Section>
    </>
  );
};

export default FrameForm;
