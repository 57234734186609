import React from 'react';
import { Button, Modal, Progress } from 'antd';
import { notEqual } from 'ramda-adjunct';
import { equals } from 'ramda';

const ModalLoading = ({ visible, onClick, progress, infoLoading, textProgression, elapsedTime }) => {
  return (
    <Modal
      className="modal-loading"
      visible={visible}
      footer={[
        <Button
          key="ok"
          type="primary"
          disabled={notEqual(100, progress)}
          onClick={onClick}
        >
          Terminer
        </Button>
      ]}
      width="25%"
      centered
      closable={false}
    >
      {infoLoading}
      <div className="flex w-full justify-between">
        <Progress
          percent={progress}
          status={equals(100, progress) ? 'normal' : 'active'}
          showInfo={false}
          strokeColor={{ from: 'var(--flexibranche-wildsand)', to: 'var(--flexidocs-turquoise)' }}
        />
        <p className="ml-4 w-24 text-right">{textProgression}</p>
      </div>
      { elapsedTime && (
        <div>{`Temps écoulé : ${elapsedTime}s`}</div>
      )}
    </Modal>
  );
};

export default ModalLoading;
