import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Collapse } from '../../../components';
import GlobalVariablesTable from '../../docs/components/globalVariable/GlobalVariablesTable';
import { QGlobalVariablesQuery } from '../../../_graphql/queries/documents/QGlobalVariables';
import { SCOPE_OFFER } from '../../../_CONST';
import useFetchQuery from '../../../hooks/useFetchQuery';

const GlobalVariablesSection = ({
  offerId,
  userCanEditOffer
}) => {
  const history = useHistory();
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const { globalVariables, isLoading, reFetch } = useFetchQuery({
    query: QGlobalVariablesQuery,
    args: { offerId, scope: SCOPE_OFFER },
    dataProp: 'globalVariables',
    defaultData: null,
    skip: !collapseIsOpen || hasFetched
  });
  useEffect(() => {
    if (collapseIsOpen && !hasFetched && !isLoading && globalVariables) {
      setHasFetched(true);
    }
  }, [collapseIsOpen, hasFetched, isLoading, globalVariables]);

  return (
    <Collapse
      className="section-global-variable"
      collapseIsOpen={collapseIsOpen}
      setCollapseIsOpen={setCollapseIsOpen}
      title="Variables globales de l'offre"
      actions={userCanEditOffer && (
        <Button
          onClick={() => history.push(`/offre/${offerId}/edition/variables-globales/creer`)}
          icon={<PlusOutlined />}
        >
          Créer une variable globale
        </Button>
      )}
    >
      <GlobalVariablesTable
        globalVariables={globalVariables}
        userCanEditOffer={userCanEditOffer}
        offerId={offerId}
        reFetch={reFetch}
      />
    </Collapse>
  );
};

export default GlobalVariablesSection;
