import React from 'react';
import { compose, reject, isNil, defaultTo, prop, not, isEmpty, find, propEq } from 'ramda';
import { Popconfirm, Table, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { renderDate } from '../helpers/dates';
import { Trash, Edit } from '../../../../components/icons';
import TableDates from './TableDates';

const TagsTable = ({ tags, userCanEditOffer, onClickPreview, onClickEdit, onClickDelete, getLink, reFetch }) => {
  tags = compose(
    reject(isNil),
    defaultTo([])
  )(tags);

  return (
    <Table
      size="small"
      dataSource={tags}
      rowKey="id"
      pagination={false}
    >
      <Table.Column
        title="Nom"
        dataIndex="name"
        defaultSortOrder="ascend"
        width={300}
        sorter={(a, b) => prop('name', a).toLowerCase().localeCompare(prop('name', b).toLowerCase())}
        render={(name, tag) => <Link to={getLink(tag)}>{name}</Link>}
      />

      <Table.Column
        title="Type"
        dataIndex="itemType"
        width={300}
        render={(type) => (
          <span className="capitalize">{type}</span>
        )}
      />

      <TableDates renderDate={renderDate} />

      <Table.Column
        title=""
        dataIndex="id"
        render={(tagId) => {
          if (userCanEditOffer) {
            const isTagUsed = compose(
              not,
              isEmpty,
              prop('itemsIds'),
              find(propEq('id', tagId))
            )(tags);

            return (
              <div className="flex justify-end gap-2 text-lg">
                <Tooltip
                  title="Éditer le tag"
                  color="#0197DC"
                >
                  <Edit
                    className="btn-edit-tag grey"
                    onClick={() => onClickEdit(tagId)}
                  />
                </Tooltip>
                <Popconfirm
                  title="Êtes-vous sûr de vouloir supprimer ce tag ?"
                  onConfirm={() => onClickDelete(tagId, reFetch)}
                  disabled={isTagUsed}
                >
                  <Tooltip
                    title={
                      isTagUsed
                        ? 'Ce tag est utilisé, vous ne pouvez pas le supprimer.'
                        : 'Supprimer le tag'
                    }
                    color="#0197DC"
                  >
                    <Trash className="btn-remove-tag hover:text-red-300 grey"/>
                  </Tooltip>
                </Popconfirm>
              </div>
            );
          }
          return (
            <div className="flex justify-end gap-2 text-lg">
              <Tooltip
                title="Voir le tag"
                color="#0197DC"
              >
                <EyeOutlined onClick={() => onClickPreview(tagId)} />
              </Tooltip>
            </div>
          );
        }}
      />
    </Table>
  );
};

export default TagsTable;
