import { CheckSquareOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { prop } from 'ramda';
import React from 'react';
import withUser from '../../../../../../withUser';
import VariableContainer from './VariableContainer';

const SelectVariable = ({ userIsAdmin, label, value, onChange, options, multiple, onChangeIsDefaultValue, isDefaultValue }) => (
  <VariableContainer
    label={label}
    icon={<CheckSquareOutlined />}
    onChangeIsDefaultValue={onChangeIsDefaultValue}
    isDefaultValue={isDefaultValue}
    value={value}
  >
    <Select
      mode={multiple && 'multiple'}
      allowClear={userIsAdmin}
      className="w-full"
      {...{ onChange, value }}
      disabled={!userIsAdmin}
    >
      {options.map((option) => (
        <Select.Option
          key={prop('value', option)}
          value={prop('value', option)}
        >
          {prop('title', option)}
        </Select.Option>
      ))}
    </Select>
  </VariableContainer>
);

export default withUser(SelectVariable);
