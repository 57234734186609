import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import React from 'react';
import classNames from 'classnames';
import withUser from '../../../../../../withUser';

const logoChecked = {
  checked: <CheckSquareOutlined />,
  unchecked: <BorderOutlined />
};

const DefaultValuesCheck = ({ onChangeIsDefaultValue, checked, userIsAdmin }) => {
  const format = checked ? 'checked' : 'unchecked';
  return (
    <div>
      <span onClick={() => userIsAdmin && onChangeIsDefaultValue(!checked)}>
        {logoChecked[format]}&nbsp;
      </span>
      <span
        className={classNames('text-xs inline-block align-middle cursor-pointer', { 'cursor-not-allowed': !userIsAdmin })}
        onClick={() => userIsAdmin && onChangeIsDefaultValue(!checked)}
      >
        Valeur par défaut
      </span>
    </div>
  );
};

export default withUser(DefaultValuesCheck);
