import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QAllDocumentsQuery = graphql`
  query QAllDocumentsQuery($scope: DocumentScopeEnum!, $offerId: ID) {
    allDocuments(scope: $scope, offerId: $offerId) {
      id
      name
      scope
      offerId
      variables
      rootDocumentId
      rights {
        userIsDocumentValidator
        userIsDocumentEditor
        userIsDocumentTechEditor
        userIsDocumentReader
      }
      padding {
        top
        right
        bottom
        left
      }
      type
      dates {
        creation
        lastUpdate
      }
      pages {
        breakPage
        padding {
          top
          bottom
          left
          right
        }
        widgets {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          breakPage
          padding {
            top
            right
            bottom
            left
          }
        }
        header {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          padding {
            top
            right
            bottom
            left
          }
        }
        footer {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          padding {
            top
            right
            bottom
            left
          }
        }
      }
      version {
        versionId
        itemId
        status
        itemScope
        itemType
        major
        minor
        reviewComments {
          comments
          rejectionReason
          status
        }
      }
      offerVersion {
        major
        minor
      }
    }
    rawDocuments(offerId: $offerId) {
      id
      filename
      name
      offerId
      scope
      dates {
        creation
        lastUpdate
      }
    }
  }
`;

const QAllDocuments = ({
  children,
  childDataProp,
  args
}) => {
  return (
    <Query
      query={QAllDocumentsQuery}
      caching={false}
      args={args}
    >
      {populateChildren()(children, childDataProp)}
    </Query>
  );
};

export default QAllDocuments;
