import { ColumnHeightOutlined, ColumnWidthOutlined, DeleteOutlined, HistoryOutlined, MinusOutlined, PlusOutlined, ThunderboltOutlined } from '@ant-design/icons';
import '@fasstech/flexiget/dist/css/main.css';
import '@fasstech/flexiget-mme/dist/css/main.css';
import '@fasstech/flexiget-april/dist/css/main.css';
import { Button, Input, InputNumber, Popconfirm, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { isNotNilOrEmpty, isTrue } from 'ramda-adjunct';
import { always, and, assocPath, concat, cond, equals, gt, ifElse, includes, indexOf, join, lt, map, not, or, path, prop, propOr, sortBy, T } from 'ramda';
import { Section, Title } from '../../../../components';
import useWidget from '../../../../hooks/useWidget';
import withUser from '../../../../withUser';
import QPredefinedVariables from '../../../../_graphql/queries/QPredefinedVariables';
import { getFlexigetOptionsElement, getFlexigetSizeElements } from '../helpers/flexigets';
import { editorWidth } from '../helpers/widget';
import Card from '../layoutItems/Card';
import DropZone from '../layoutItems/DropZone';
import ElementsBar from '../layoutItems/ElementsBar';
import useColors from '../../../../hooks/useColors';
import useStyles from '../../../../hooks/useStyles';
import useDefaultFont from '../../../../hooks/useDefaultFont';
import Loading from '../../../../components/Loading';
import EditStyleModal from '../document/EditStyleModal';
import { Rule } from '../rules/DocumentRules';
import useFetchQuery from '../../../../hooks/useFetchQuery';
import { QGlobalVariablesQuery } from '../../../../_graphql/queries/documents/QGlobalVariables';
import CreateGlobalVariableModal from '../document/CreateGlobalVariableModal';
import SearchBar from './SearchBar';
import WidgetIsUsed from './WidgetIsUsed';
import SelectOrientation from './SelectOrientation';
import FrameStyle from './FrameStyle';

const WidgetForm = ({
  widget,
  scope,
  offerId,
  modalMode = false,
  hideModal,
  preview,
  flexigets,
  predefinedVariables,
  setRefetch,
  userTeam,
  totalPaddingX = 0,
  setWidgetCreated,
  onCancel,
  updateWidget,
  userIsAdmin
}) => {
  const onOfferRedirect = () => ifElse(
    equals(true),
    () => {
      hideModal && hideModal();
      return `/offre/${offerId}/edition/documents/creer`;
    },
    always(`/offre/${offerId}/edition`)
  )(modalMode);

  const { globalVariables, reFetch, isLoading: isLoadingGlobalVariables } = useFetchQuery({
    query: QGlobalVariablesQuery,
    args: {
      scope,
      offerId
    },
    dataProp: 'globalVariables',
    defaultData: []
  });

  const {
    values,
    loading,
    deleteLoading,
    isValid,
    setState,
    onSave,
    isEdit,
    onDelete,
    isUsed,
    isUsedBy,
    widgetExist,
    elements,
    isEditing,
    variables,
    getConditionsAreValid,
    updateRule,
    removeRule,
    isOpenCreateGlobalVariableModal,
    setIsOpenCreateGlobalVariableModal
  } = useWidget({
    widget, scope, offerId, onOfferRedirect, flexigets, setWidgetCreated
  });
  const { colors } = useColors();
  const { styles, reFetch: reFetchStyle } = useStyles();
  const { defaultFont, isLoading } = useDefaultFont({ teamId: prop('teamId', userTeam) });
  const { pathname } = useLocation();
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');
  const [visibleWidgets, setVisibleWidgets] = useState([]);
  const [yScrollBarPosition, setYScrollBarPosition] = useState(0);
  const [showYScrollBar, setShowYScrollBar] = useState(false);
  const [isDownNbColsGrid, setIsDownNbColsGrid] = useState();
  const [isClosedElement, setIsClosedElement] = useState(false);
  const [isClosedWidget, setIsClosedWidget] = useState(false);
  const [isFocus, setIsFocus] = useState([]);
  const [useCSSTransforms, setUseCSSTransforms] = useState(true);
  const [showCreateStyleModal, setShowCreateStyleModal] = useState(false);

  const defaultFontValue = useMemo(() => prop('value', defaultFont), [defaultFont]);
  const hasDynamicTable = useMemo(() => {
    return values.contents.some(element => element.type === 'dynamicTable');
  }, [values.contents]);

  const hasPaddingX = gt(totalPaddingX, 0);
  const widgetIsEditable = and(or(isEdit, widgetExist), not(preview));

  const widgetMinSize = getFlexigetSizeElements(flexigets);
  const widgetTypes = getFlexigetOptionsElement(flexigets);
  const widgetName = prop('name', values);

  const yScrollBarStyle = showYScrollBar ? 'overflow-y-scroll' : '';
  const yScrollElements = visibleWidgets.length > 10 ? 'h-750px overflow-y-auto' : '';

  const addPrefixToWidgetNameIfLandscape = cond([
    [equals('landscape'), always(`[PAYSAGE] - ${widgetName}`)],
    [T, always(widgetName)]
  ])(prop('orientation', values));

  const handleWidgetName = isNotNilOrEmpty(widgetName) ? widgetName : addPrefixToWidgetNameIfLandscape;

  const infoPredefinedVariables = (
    <p className="p-2 whitespace-pre-line">Des variables prédéfinies sont
      disponibles
      : <br/>{join(',\r\n', map((pred) => `- ${prop('key', pred)}: ${prop('description', pred)}`)(predefinedVariables))}
    </p>
  );

  const title = cond([
    [() => widgetIsEditable, always('Modifier le widget')],
    [() => isTrue(preview), always('Prévisualiser le widget')],
    [T, always('Créer un widget')]
  ])(widget);

  const backToTitle = cond([
    [() => modalMode, always(null)],
    [includes('/offre'), always(`/offre/${offerId}/edition`)],
    [includes('/equipe'), always('/equipe/elements/widgets')]
  ])(pathname);

  const inputsAreDisabled = preview || !userIsAdmin;
  const canEdit = !preview && userIsAdmin;
  const isInCreation = and(not(widgetExist), not(preview));
  const hasRightToEditWidget = userIsAdmin && (widgetIsEditable || isEdit || isInCreation);

  const orderElementsByAsc = (elements) => sortBy(prop('label'))(elements);

  const onSaveAndQuit = async () => {
    const res = await onSave(elements, null, !modalMode);
    if (res && modalMode) {
      if (setRefetch) setRefetch(true);
      const newName = prop('name', values);
      updateWidget && updateWidget(values, newName);
      hideModal && hideModal();
    }
  };

  const changeNbColsGrid = (value) => {
    setIsDownNbColsGrid(lt(value, prop('nbColsGrid', values)));
    setState(assocPath(['values', 'nbColsGrid'], value));
  };
  const changeHeightRowsGrid = (value) => setState(assocPath(['values', 'heightRowsGrid'], value));
  const changeOrientation = (value) => setState(assocPath(['values', 'orientation'], value));

  const openCreateStyleModal = () => setShowCreateStyleModal(true);

  const closeCreateStyleModal = (ok) => {
    if (equals(true, ok)) {
      reFetchStyle();
    }
    setShowCreateStyleModal(false);
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredList = widgetTypes.filter((widget) => {
        return prop('label', widget).toLowerCase().includes(searchTerm.toLowerCase());
      });
      setVisibleWidgets(orderElementsByAsc(filteredList));
    } else {
      setVisibleWidgets(orderElementsByAsc(widgetTypes));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (yScrollBarPosition >= 880) {
      setShowYScrollBar(true);
    } else {
      setShowYScrollBar(false);
    }
  }, [yScrollBarPosition]);

  useEffect(() => {
    if (hasDynamicTable) setIsClosedWidget(true);
  }, [hasDynamicTable]);

  const setGlobalVariableCreated = () => {
    setIsOpenCreateGlobalVariableModal(false);
    reFetch();
  };

  const indexFocus = indexOf(true, isFocus);
  const globalVariablesForRules = map((v) => ({ type: prop('type', v), key: prop('name', v) }), globalVariables);
  const allVariables = concat(variables, globalVariablesForRules);

  if (isLoading || isLoadingGlobalVariables) return <Loading />;

  return (
    <div>
      <Title
        title={title}
        backTo={backToTitle}
      />
      {(isEdit || widgetExist) && !modalMode && (
        <Button
          size="small"
          onClick={() => history.push(`/offre/${offerId}/widgets/${prop('id', widget)}/historique`)}
          icon={<HistoryOutlined/>}
        >
          Historique du widget
        </Button>
      )}

      <Section
        title="Nom du widget"
      >
        <div className="flex gap-x-6">
          <Input
            placeholder="Nom du widget"
            value={handleWidgetName}
            onChange={e => setState(assocPath(['values', 'name'], e.target.value))}
            className="w-1/2"
            disabled={inputsAreDisabled}
          />
          <Tooltip title="Nombre de colonnes" color="#005982">
            <InputNumber
              addonBefore={<ColumnWidthOutlined/>}
              min={1}
              controls={{
                upIcon: <PlusOutlined/>,
                downIcon: <MinusOutlined/>
              }}
              value={prop('nbColsGrid', values)}
              onChange={changeNbColsGrid}
              className="w-32"
              disabled={inputsAreDisabled}
            />
          </Tooltip>
          <Tooltip title="Hauteur minimale d'une ligne" color="#005982">
            <InputNumber
              addonBefore={<ColumnHeightOutlined/>}
              max={50}
              min={1}
              controls={{
                upIcon: <PlusOutlined/>,
                downIcon: <MinusOutlined/>
              }}
              value={prop('heightRowsGrid', values)}
              onChange={changeHeightRowsGrid}
              className="w-32"
              disabled={inputsAreDisabled}
            />
          </Tooltip>
          <SelectOrientation
            defaultOrientation={prop('orientation', values)}
            changeOrientation={changeOrientation}
            disabled={inputsAreDisabled}
          />
        </div>
      </Section>
      <div className="flex items-start justify-between">
        <div className={`h-910px w-10/12 pb-12 scrollbar mx-auto pt-6 pr-6 ${yScrollBarStyle}`}>
          <div
            className={`section-widget-zone mx-auto bg-white relative ${hasPaddingX ? 'mt-32' : 'mt-0'} ${hasPaddingX && showYScrollBar ? 'mb-32' : 'mb-0'}`}
            // eslint-disable-next-line no-restricted-syntax
            style={{ width: `${editorWidth(propOr('portrait', 'orientation', values))}px` }}
          >
            {hasPaddingX && (
              // eslint-disable-next-line no-restricted-syntax
              <><div className="absolute h-36 -top-36 border-r border-flexibranche-lightblue border-dotted" style={{ right: `${totalPaddingX}px` }} />
                {/* eslint-disable-next-line no-restricted-syntax */}
                <div className="absolute -top-36 bg-flexibranche-lightblue text-white text-xs p-1.5" style={{ right: `${totalPaddingX}px` }}>
                  <p>{'Le document s\'arrête ici'}</p>
                </div>
                {/* eslint-disable-next-line no-restricted-syntax */}
                <div className="absolute h-36 -bottom-36 border-r border-flexibranche-lightblue border-dotted" style={{ right: `${totalPaddingX}px` }} />
              </>
            )}
            <DropZone
              values={values}
              setState={setState}
              setYScrollBarPosition={setYScrollBarPosition}
              isDownNbColsGrid={isDownNbColsGrid}
              widgetIsEditable={hasRightToEditWidget}
              elements={elements}
              optionsElement={widgetTypes}
              widgetMinSize={widgetMinSize}
              defaultFont={defaultFontValue}
              customColors={colors}
              customStyles={styles}
              isFocus={isFocus}
              setIsFocus={setIsFocus}
              useCSSTransforms={useCSSTransforms}
              setUseCSSTransforms={setUseCSSTransforms}
              onCreateStyle={openCreateStyleModal}
            />
          </div>
        </div>
        {canEdit && (
          <div className="w-200px space-y-2" id="style-bar">
            <ElementsBar
              title="ajouter élément"
              isClosed={isClosedElement}
              setIsClosed={setIsClosedElement}
            >
              <div
                className={classNames(
                  'w-36',
                  { hidden: isClosedElement }
                )}
              >
                <SearchBar
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                <div className={`grid grid-cols-1 gap-2 scrollbar ${yScrollElements}`}>
                  {visibleWidgets.map(({
                    key,
                    label
                  }) => (
                    <Card
                      key={key}
                      type={key}
                      label={label}
                      hasDynamicTable={hasDynamicTable}
                      setIsFocus={setIsFocus}
                      setUseCSSTransforms={setUseCSSTransforms}
                    />
                  ))}
                </div>
              </div>
            </ElementsBar>
            <div>
              <ElementsBar title="mise en page - élément">
                <div id="element-toolbar"/>
              </ElementsBar>
            </div>
            <div>
              {indexFocus >= 0 && (
                <ElementsBar title="ajouter une règle">
                  <Rule
                    rule={path(['contents', indexFocus, 'rule'], values)}
                    variables={allVariables}
                    onUpdate={updateRule(indexFocus)}
                    onRemove={removeRule(indexFocus)}
                    ruleIndex={indexFocus}
                    getConditionsAreValid={getConditionsAreValid}
                    preview={not(hasRightToEditWidget)}
                    userIsAdmin={userIsAdmin}
                    setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
                    isWidget
                  />
                </ElementsBar>
              )}
            </div>
            <div>
              {hasDynamicTable ? (
                <ElementsBar title="mise en page - widget" />
              ) : (
                <ElementsBar
                  title="mise en page - widget"
                  className="section-style-widget"
                  isClosed={isClosedWidget}
                  setIsClosed={setIsClosedWidget}
                >
                  {!isClosedWidget && <FrameStyle values={values} setState={setState} colors={colors} />}
                </ElementsBar>
              )}
            </div>
            <div>
              <ElementsBar
                title="Variables"
                info={infoPredefinedVariables}
              >
                <div id="element-variable-bar"/>
              </ElementsBar>
            </div>
          </div>
        )}
      </div>
      {canEdit && isUsed && <WidgetIsUsed isUsedBy={isUsedBy} isEditing={isEditing} modalMode={modalMode} />}
      {canEdit && (
        <div className="btns-actions flex items-center justify-center gap-x-4 mt-4">
          {widgetIsEditable && !modalMode && (
            <>
              {!isUsed && (
                <Popconfirm
                  title="Êtes-vous sûr de vouloir supprimer ce widget ?"
                  onConfirm={onDelete}
                >
                  <Button
                    type="danger"
                    className="!h-45px text-sm"
                    loading={deleteLoading}
                    icon={<DeleteOutlined className="text-[24px]"/>}
                  >
                    Supprimer le widget
                  </Button>
                </Popconfirm>
              )}

              {and(isUsed, !modalMode) && (
                <>
                  <Button
                    type="danger"
                    loading={deleteLoading}
                    className="!h-45px text-sm"
                    icon={<DeleteOutlined className="text-[24px]"/>}
                    disabled
                  >
                    Supprimer le widget
                  </Button>

                </>
              )}
            </>
          )}
          {widgetIsEditable && modalMode && (
            <Button
              className="!h-45px text-sm"
              onClick={onCancel}
            >
              Annuler
            </Button>
          )}
          {!modalMode && (
            <Button
              className="widget-save-button !h-45px text-sm"
              type="primary"
              disabled={!isValid}
              loading={loading}
              onClick={() => onSave(elements, true)}
              icon={<ThunderboltOutlined className="text-[24px]"/>}
            >
              <span className="font-semibold">Enregistrer</span>
            </Button>
          )}

          <Button
            className="widget-save-and-quit-button !h-45px text-sm"
            type="primary"
            disabled={!isValid}
            loading={loading}
            onClick={onSaveAndQuit}
            icon={<ThunderboltOutlined className="text-[24px]"/>}
          >
            <span className="font-semibold">{modalMode ? 'Enregistrer' : 'Enregistrer et quitter'}</span>
          </Button>
        </div>
      )}
      <EditStyleModal show={showCreateStyleModal} onCancel={closeCreateStyleModal} />
      {isOpenCreateGlobalVariableModal && (
        <CreateGlobalVariableModal
          isOpenCreateGlobalVariableModal={isOpenCreateGlobalVariableModal}
          offerId={offerId}
          setGlobalVariableCreated={setGlobalVariableCreated}
          setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
        />
      )}
    </div>
  );
};

const WidgetFormWrapper = (props) => (
  <QPredefinedVariables>
    {({ predefinedVariables }) => <WidgetForm predefinedVariables={predefinedVariables || []} {...props} />}
  </QPredefinedVariables>
);
export default withUser(WidgetFormWrapper);
