import React from 'react';
import { compose, map, propOr, sortBy, indexOf, keys, toPairs, prop } from 'ramda';
import { InputNumber, Tooltip } from 'antd';
import { ExpandOutlined } from '@ant-design/icons';
import withUser from '../../../../../withUser';

const translatePadding = {
  top: 'Haut',
  right: 'Droit',
  bottom: 'Bas',
  left: 'Gauche'
};

const PaddingInfo = ({ updatePadding, element, userIsAdmin }) => (
  <div className="w-full items-center flex justify-between mb-2">
    <Tooltip
      title="Gestion du padding"
      color="#0197DC"
    >
      <ExpandOutlined />
    </Tooltip>
    {compose(
      map(([key, paddingType]) => {
        return (
          <div key={key} className="flex">
            <p className="mr-2">{propOr('', key, translatePadding)}</p>
            <InputNumber
              onChange={(value) => updatePadding(key, value)}
              value={paddingType}
              defaultValue={0}
              min={0}
              size="small"
              formatter={value => `${value}px`}
              parser={value => value.replace('px', '')}
              disabled={!userIsAdmin}
            />
          </div>
        );
      }),
      sortBy(([key]) => indexOf(key, keys(translatePadding))),
      toPairs
    )(prop('padding', element))}
  </div>
);

export default withUser(PaddingInfo);
