import { useState } from 'react';
import { propOr, assocPath, isEmpty, prop, equals, propEq } from 'ramda';
import UpdateReviewCommentsMutation from '../_graphql/mutations/reviews/UpdateReviewCommentsMutation';
import AcceptReviewMutation from '../_graphql/mutations/reviews/AcceptReviewMutation';
import RejectReviewMutation from '../_graphql/mutations/reviews/RejectReviewMutation';
import { errorMessage, successMessage } from '../utils/messageMutation';

const useReview = (version, reviewComments, item, reFetch) => {
  const defaultComments = propOr('', 'comments', reviewComments);

  const [state, setState] = useState({
    loading: {
      updateComment: false,
      accept: false,
      reject: false
    },
    comments: defaultComments,
    rejectionReason: ''
  });

  const updateState = (k, v) => setState(assocPath(k.split('.'), v));

  const isValid = !isEmpty(prop('comments', state)) && !equals(defaultComments, prop('comments', state));

  const updateComments = () => {
    updateState('loading.updateComment', true);

    UpdateReviewCommentsMutation({
      versionId: prop('versionId', version),
      comments: prop('comments', state)
    }, (ok, error) => {
      updateState('loading.updateComment', false);

      if (ok && !error) {
        successMessage('commentaires', 'mis à jour');
        reFetch && reFetch();
      } else {
        errorMessage();
      }
    });
  };

  const acceptReview = () => {
    updateState('loading.accept', true);

    AcceptReviewMutation({ versionId: prop('versionId', version), itemId: prop('id', item) }, (ok, error) => {
      updateState('loading.accept', false);

      if (ok && !error) {
        successMessage('offre', 'publiée', true);
        reFetch && reFetch();
      } else {
        errorMessage();
      }
    });
  };

  const rejectReview = () => {
    updateState('loading.reject', true);

    RejectReviewMutation({
      versionId: prop('versionId', version), reason: prop('rejectionReason', state), itemId: prop('id', item)
    }, (ok, error) => {
      updateState('loading.reject', false);

      if (ok && !error) {
        successMessage('publication de l\'offre', 'refusée', false, true);
        reFetch && reFetch();
      } else {
        errorMessage();
      }
    });
  };

  return {
    ...state,
    updateState,
    isValid,
    updateComments,
    acceptReview,
    rejectReview,
    isReview: propEq('status', 'review', version),
    isDraft: propEq('status', 'draft', version),
    reviewIsRejected: propEq('status', 'rejected', reviewComments)
  };
};

export default useReview;
