/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QOfferQueryVariables = {|
  offerId: string
|};
export type QOfferQueryResponse = {|
  +offer: ?{|
    +id: ?string,
    +offerName: ?string,
    +offerId: ?string,
    +team: ?string,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +version: ?{|
      +major: ?number,
      +minor: ?number,
      +status: ?string,
      +itemScope: ?string,
      +itemType: ?string,
      +itemRootId: ?string,
      +versionId: ?string,
      +itemId: ?string,
      +isLastMajor: ?boolean,
      +isLastMinor: ?boolean,
      +reviewComments: ?{|
        +comments: ?string,
        +rejectionReason: ?string,
        +status: ?string,
      |},
    |},
  |}
|};
export type QOfferQuery = {|
  variables: QOfferQueryVariables,
  response: QOfferQueryResponse,
|};
*/


/*
query QOfferQuery(
  $offerId: ID!
) {
  offer(offerId: $offerId) {
    id
    offerName
    offerId
    team
    dates {
      creation
      lastUpdate
    }
    version {
      major
      minor
      status
      itemScope
      itemType
      itemRootId
      versionId
      itemId
      isLastMajor
      isLastMinor
      reviewComments {
        comments
        rejectionReason
        status
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "offerId",
    "variableName": "offerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "team",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "major",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemScope",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemRootId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLastMajor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLastMinor",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rejectionReason",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QOfferQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "offer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewComment",
                "kind": "LinkedField",
                "name": "reviewComments",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QOfferQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "offer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewComment",
                "kind": "LinkedField",
                "name": "reviewComments",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "769246210abade0b3b2b23715d5cbc1c",
    "id": null,
    "metadata": {},
    "name": "QOfferQuery",
    "operationKind": "query",
    "text": "query QOfferQuery(\n  $offerId: ID!\n) {\n  offer(offerId: $offerId) {\n    id\n    offerName\n    offerId\n    team\n    dates {\n      creation\n      lastUpdate\n    }\n    version {\n      major\n      minor\n      status\n      itemScope\n      itemType\n      itemRootId\n      versionId\n      itemId\n      isLastMajor\n      isLastMinor\n      reviewComments {\n        comments\n        rejectionReason\n        status\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b71083983c75fc2e060035dd7072484a';

module.exports = node;
