import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { prop, propOr } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import useApiKey from '../../../../hooks/useApiKey';
import withEnvs from '../../../../withEnvs';
import getCurlTemplate from './curlTemplate';
import useOfferMetadata from './useOfferMetadata';
import SectionCopyCurl from './SectionCopyCurl';
const TagCurlSection = ({
  bundleTag,
  offerId,
  teams,
  userTeam,
  offer,
  env = {}
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idPdfFile, setIdPdfFile] = useState('idPDF');
  const [idRawFile, setIdRawFile] = useState('idDocument');
  const { metadata } = useOfferMetadata(offerId, bundleTag);
  const [variables, setVariables] = useState(JSON.stringify({}));

  useEffect(() => isNotEmpty(metadata) && setVariables(metadata), [metadata]);

  const { teamApiKey } = useApiKey(teams, userTeam);

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);
  const handleChange = (event) => setVariables(event.target.value);

  const {
    FLEXIBRANCHE_API_URL,
    X_API_KEY
  } = env;

  const version = propOr({}, 'version', offer);

  const {
    getRawPdf,
    getDocumentVariables,
    getDocumentUrl,
    getPdf
  } = getCurlTemplate({
    apiUrl: FLEXIBRANCHE_API_URL,
    offerId,
    teamApiKey,
    version: `${prop('major', version)}.${prop('minor', version)}`,
    xApiKey: X_API_KEY
  });

  const templateDocumentVariables = getDocumentVariables(bundleTag);
  const templateDocumentUrl = getDocumentUrl(bundleTag, variables);
  const templatePdf = getPdf(idPdfFile, prop('teamId', userTeam));
  const templateRawPdf = getRawPdf(idRawFile, prop('teamId', userTeam));

  return (
    <>
      <SectionCopyCurl
        title="Récupérer la liste de variables"
        template={templateDocumentVariables}
      />

      <SectionCopyCurl
        title="Générer le(s) PDF(s)"
        template={templateDocumentUrl}
        additionalActions={(
          <Button
            onClick={showModal}
            icon={<PlusOutlined/>}
          >
            Modifier les variables
          </Button>
        )}
      >
        <Modal
          bodyStyle={{ minHeight: '300px' }}
          title="Modification de variables"
          visible={isModalVisible}
          onOk={hideModal}
          onCancel={hideModal}
        >
          <textarea
            id="variables-text-area"
            rows="10"
            className="w-full"
            value={variables}
            onChange={handleChange}
          />
        </Modal>
      </SectionCopyCurl>

      <SectionCopyCurl
        title={'Récupérer un PDF créé depuis l\'éditeur'}
        template={templatePdf}
        additionalActions={(
          <Input
            className="h-10"
            placeholder="Id du PDF"
            onChange={(e) => setIdPdfFile(e.target.value)}
            size="default"
          />
        )}
      />

      <SectionCopyCurl
        title="Récupérer un PDF importé"
        template={templateRawPdf}
        additionalActions={(
          <Input
            className="h-10"
            placeholder="Id du document"
            onChange={(e) => setIdRawFile(e.target.value)}
            size="default"
          />
        )}
      />
    </>
  );
};

export default withEnvs(TagCurlSection);
