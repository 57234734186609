import { DeleteOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { always, append, assoc, compose, defaultTo, gt, length, lensPath, over, pathOr, remove, repeat, when } from 'ramda';
import React from 'react';
import { mapIndexed } from '../../../../../../utils/mapIndexed';
import Input from '../../Input';
import withUser from '../../../../../../withUser';
import VariableContainer from './VariableContainer';

const ListVariables = ({ userIsAdmin, label, values, fields, setState, onChangeIsDefaultValue, isDefaultValue }) => {
  const addValue = () => setState(over(
    lensPath(['values', 'variables', label]),
    compose(
      append({}),
      defaultTo([])
    )
  ));

  const removeValue = (valueIndex) => setState(over(
    lensPath(['values', 'variables', label]),
    remove(valueIndex, 1)
  ));

  const onChange = (valueIndex, field, value) => setState(over(
    lensPath(['values', 'variables', label, valueIndex]),
    compose(
      assoc(field, value),
      when(
        compose(
          gt(length(fields)),
          length
        ),
        always(repeat('', length(fields)))
      )
    )
  ));

  return (
    <VariableContainer
      label={label}
      icon={<UnorderedListOutlined />}
      onChangeIsDefaultValue={onChangeIsDefaultValue}
      isDefaultValue={isDefaultValue}
      value={values}
    >
      <Collapse bordered={false}>
        <Collapse.Panel key="1" header="Saisir la liste des variables">
          {mapIndexed((value, valueIndex) => {
            return (
              <div key={`label-${valueIndex}-row`}>
                <b>{valueIndex + 1}.</b>
                <DeleteOutlined
                  className="text-red-500"
                  onClick={() => removeValue(valueIndex)}
                  disabled={!userIsAdmin}
                />
                <div className="grid grid-cols-2 gap-x-2">
                  {mapIndexed((field, fieldIndex) => {
                    return (
                      <Input
                        key={`field-${field}-${fieldIndex}`}
                        placeholder={field}
                        value={pathOr('', [valueIndex, field], values)}
                        onChange={e => onChange(valueIndex, field, e.target.value)}
                        disabled={!userIsAdmin}
                      />
                    );
                  })(fields)}

                </div>
              </div>
            );
          })(values)}
          {userIsAdmin && (
            <Button
              icon={<PlusOutlined />}
              onClick={addValue}
              size="small"
            >
              Ajouter une entrée
            </Button>
          )}
        </Collapse.Panel>
      </Collapse>
    </VariableContainer>
  );
};

export default withUser(ListVariables);
