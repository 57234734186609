import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../.';
import { populateChildren } from './toolbox';

export const QTeamInvitationsQuery = graphql`
  query QTeamInvitationsQuery($email: String!) {
    teamInvitations(email: $email) {
      id
      teamId
      teamName
      creation
      status
    }
  }
`;

const QTeamInvitations = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    query={QTeamInvitationsQuery}
    args={args}
  >
    {populateChildren(['teamInvitations'])(children, childDataProp)}
  </Query>
);

export default QTeamInvitations;
