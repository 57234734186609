import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { adjust, always, and, assoc, assocPath, compose, cond, defaultTo, find, findIndex, gt, ifElse, isEmpty, isNil, length, lensPath, lensProp, map, move, not, or, over, path, pick, prop, propEq, propOr, remove, T, test, update } from 'ramda';
import { isNotEmpty, isNotNil, isNotNilOrEmpty, isTrue } from 'ramda-adjunct';
import { Badge, Button, Input, Select, Table, Tooltip } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, SlidersOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Components } from '@fasstech/flexiget';
import { ChevronBottom, Section, Title } from '../../../../components';
import UpdateTagMutation from '../../../../_graphql/mutations/documents/UpdateTagMutation';
import CreateTagMutation from '../../../../_graphql/mutations/documents/CreateTagMutation';
import withOfferRights from '../../../offer/withOfferRights';
import withUser from '../../../../withUser';
import DocumentRules from '../rules/DocumentRules';
import useRules from '../rules/useRules';
import { QGlobalVariablesQuery } from '../../../../_graphql/queries/documents/QGlobalVariables';
import ColorBlock from '../../../elements/colors/components/ColorBlock';
import { callbackSave } from '../../../../utils/messageMutation';
import useColors from '../../../../hooks/useColors';
import defaultColors from '../../../../utils/defaultColors';
import { DEFAULT_COLOR } from '../../../../_CONST';
import CreateGlobalVariableModal from '../document/CreateGlobalVariableModal';
import useFetchQuery from '../../../../hooks/useFetchQuery';
import Loading from '../../../../components/Loading';
import { deleteTag } from '../../../helpers/deleteTag';
import { QOfferQuery } from '../../../../_graphql/queries/offers/QOffer';
import TagCurlSection from './TagCurlSection';

const useTag = ({ tagId, tag, scope, offerId, globalVariablesFetch, isLoading }) => {
  const tagExist = isNotNil(tagId);
  const [state, setState] = useState(defaultTo({ color: DEFAULT_COLOR }, tag));
  const [globalVariableCreated, setGlobalVariableCreated] = useState();
  const [isOpenCreateGlobalVariableModal, setIsOpenCreateGlobalVariableModal] = useState(null);
  const [globalVariables, setGlobalVariables] = useState([]);

  useEffect(() => {
    if (not(isLoading) && isNotEmpty(globalVariablesFetch)) {
      setGlobalVariables(compose(
        defaultTo([]),
        map((v) => ({ type: prop('type', v), key: prop('name', v) }))
      )(globalVariablesFetch));
    }
  }, [globalVariablesFetch, isLoading]);

  useEffect(() => {
    if (isNotNil(globalVariableCreated)) {
      const { onUpdate, conditionIndex } = isOpenCreateGlobalVariableModal;
      onUpdate(assocPath(['conditions', conditionIndex, 'variable'], prop('name', globalVariableCreated)));
      setGlobalVariables([...globalVariables, { type: prop('type', globalVariableCreated), key: prop('name', globalVariableCreated) }]);
      setGlobalVariableCreated();
      setIsOpenCreateGlobalVariableModal(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalVariableCreated]);

  const history = useHistory();

  const isEdit = !isNil(tagId);
  const isValid = isNotNilOrEmpty(prop('name', state)) && isNotNilOrEmpty(prop('color', state)) && isNotNilOrEmpty(prop('itemType', state));

  const onItemUp = index => {
    setState(
      over(
        lensProp('itemsIds'),
        move(index, index - 1)
      )
    );
  };

  const onItemDown = index => {
    setState(
      over(
        lensProp('itemsIds'),
        move(index, index + 1)
      )
    );
  };

  const onSave = () => {
    if (isEdit) {
      const tag = pick(['name', 'color', 'itemsIds', 'rules'], state);
      UpdateTagMutation({ tagId, tag }, callbackSave('tags', 'Le tag a bien été mis à jour.', history, scope, state, offerId));
    } else {
      const tag = compose(
        assoc('offerId', offerId),
        assoc('scope', scope)
      )(state);
      CreateTagMutation({ tag }, callbackSave('tags', 'Le tag a bien été créé.', history, scope, state, offerId));
    }
  };

  const addTagRule = (itemId) => {
    let rules = defaultTo([])(prop('rules', state));
    const index = findIndex(propEq('itemId', itemId))(rules);
    if (index > -1) {
      const rulesAtIndex = path([index, 'rules'], rules);
      rules = assocPath([index, 'rules'], [...rulesAtIndex, {}], rules);
    } else {
      rules = [...rules, { itemId, rules: [{}] }];
    }
    setState({ ...state, rules });
  };

  const removeTagRule = (itemId) => (ruleIndex) => () => {
    const rulesItemId = findIndex(propEq('itemId', itemId))(prop('rules', state));
    ifElse(
      () => gt(length(path(['rules', rulesItemId, 'rules'], state)), 1),
      () => setState(over(
        lensPath(['rules', rulesItemId, 'rules']),
        remove(ruleIndex, 1)
      )),
      () => setState(over(
        lensPath(['rules']),
        remove(rulesItemId, 1)
      ))
    )(state);
  };

  const updateTagRule = (itemId) => (ruleIndex) => (fn) => {
    const rulesItemId = findIndex(propEq('itemId', itemId))(prop('rules', state));
    setState(over(
      lensPath(['rules', rulesItemId, 'rules']),
      adjust(ruleIndex, fn)
    ));
  };

  const removeTag = () => {
    deleteTag(tagId, (ok, error) => {
      if (ok && !error) {
        history.push(`/offre/${offerId}/edition`);
      }
    });
  };

  const {
    getRulesAreValid,
    allRulesAreValid,
    getConditionsAreValid
  } = useRules(state);

  return {
    ...state,
    setState,
    isEdit,
    onSave,
    isValid,
    onItemUp,
    onItemDown,
    getRulesAreValid,
    allRulesAreValid,
    getConditionsAreValid,
    addTagRule,
    removeTagRule,
    updateTagRule,
    tagExist,
    isOpenCreateGlobalVariableModal,
    setIsOpenCreateGlobalVariableModal,
    setGlobalVariableCreated,
    globalVariables,
    removeTag
  };
};

const TagItems = ({
  itemType,
  itemsIds = [],
  onItemUp,
  onItemDown,
  userCanEditTag,
  rules = [],
  getRulesAreValid,
  getConditionsAreValid,
  variables,
  addTagRule,
  removeTagRule,
  updateTagRule,
  preview,
  userIsAdmin,
  setIsOpenCreateGlobalVariableModal,
  tag
}) => {
  itemsIds = compose(
    map(itemId => ({ itemId })),
    defaultTo([])
  )(itemsIds);

  const [displayRules, setDisplayRules] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setDisplayRules(itemsIds.map(() => false)), []);

  const addRulesActions = or(and(userCanEditTag, !preview), and(userCanEditTag, preview, length(rules)));

  const dataSource = useMemo(
    () =>
      map((item) => {
        const itemId = prop('itemId', item);
        const rulesItem = compose(
          defaultTo([]),
          prop('rules'),
          find(propEq('itemId', itemId))
        )(rules);
        return {
          itemId,
          rules: rulesItem
        };
      })(defaultTo([])(itemsIds)),
    [itemsIds, rules]
  );

  return (
    <Table
      pagination={false}
      dataSource={dataSource}
      rowKey="itemId"
    >
      {!preview && length(dataSource) > 1 && (
        <Table.Column
          dataIndex="itemId"
          title=""
          width={80}
          render={(itemId, item, index) => {
            return (
              <div className="flex">
                <div className="w-30px">
                  {index > 0 && <ArrowUpOutlined onClick={() => onItemUp(index)} />}
                </div>

                <div className="w-30px">
                  {index < length(dataSource) - 1 && <ArrowDownOutlined onClick={() => onItemDown(index)} />}
                </div>
              </div>
            );
          }}
        />
      )}

      <Table.Column
        dataIndex="itemId"
        title="Nom"
        render={itemId => {
          if (itemType === 'document') {
            const name = propOr(itemId, 'name', find(propEq('_id', itemId))(prop('items', tag)));
            return (<span>{name}</span>);
          }

          return <span>{itemId}</span>;
        }}
      />
      {addRulesActions && (
        <Table.Column
          title=""
          render={(item, _, index) => {
            const itemId = prop('itemId', item);
            const rulesItem = prop('rules', item);

            const tooltipTitle = ifElse(
              isTrue,
              ifElse(
                () => gt(length(rules), 1),
                always('Voir le(s) règle(s)'),
                always('Voir la règle')
              ),
              always('Ajouter une règle')
            )(preview);

            return (
              <div className="flex flex-col items-end text-sm">
                <Badge
                  dot={gt(length(rulesItem), 0)}
                  color={gt(length(rulesItem), 0) ? '#0197DC' : undefined}
                >
                  <Tooltip
                    title={tooltipTitle}
                    color="#0197DC"
                  >
                    <SlidersOutlined
                      className="btn-rules"
                      onClick={() => setDisplayRules(update(index, !displayRules[index]))}
                      rotate={90}
                    />
                  </Tooltip>
                </Badge>
                {displayRules[index] && (
                  <DocumentRules
                    variables={variables}
                    rules={rulesItem}
                    rulesAreValid={getRulesAreValid(rulesItem)}
                    getConditionsAreValid={getConditionsAreValid}
                    onAdd={() => addTagRule(itemId)}
                    onRemove={removeTagRule(itemId)}
                    onUpdate={updateTagRule(itemId)}
                    preview={preview}
                    userIsAdmin={userIsAdmin}
                    setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
                  />
                )}
              </div>
            );
          }}
        />
      )}
    </Table>
  );
};

const TagForm = ({
  tagId,
  tag,
  scope,
  offerId,
  userIsOfferValidator,
  preview,
  userIsAdmin,
  teams,
  userTeam
}) => {
  const { globalVariables: globalVariablesFetch, isLoading } = useFetchQuery({
    query: QGlobalVariablesQuery,
    args: {
      scope,
      offerId
    },
    dataProp: 'globalVariables',
    defaultData: []
  });

  const {
    isEdit,
    name,
    itemType,
    color,
    rules,
    setState,
    onSave,
    isValid,
    onItemUp,
    onItemDown,
    itemsIds,
    getRulesAreValid,
    allRulesAreValid,
    getConditionsAreValid,
    addTagRule,
    removeTagRule,
    updateTagRule,
    tagExist,
    isOpenCreateGlobalVariableModal,
    setIsOpenCreateGlobalVariableModal,
    setGlobalVariableCreated,
    globalVariables,
    removeTag
  } = useTag({
    tagId, tag, scope, offerId, globalVariablesFetch, isLoading
  });

  const { offer, isLoading: isLoadingOffer } = useFetchQuery({
    query: QOfferQuery,
    args: { offerId },
    dataProp: 'offer',
    defaultData: {},
    skip: !(isEdit && offerId)
  });

  const [showColorPicker, setShowColorPicker] = useState(false);
  const tagEditable = not(preview);
  const title = cond([
    [() => tagEditable && tag, always('Modifier le tag')],
    [() => isTrue(preview), always('Prévisualiser le tag')],
    [T, always('Créer un tag')]
  ])(tag);

  const { colors, isLoading: isLoadingColors } = useColors();
  const favoritesColors = [defaultColors, colors];
  const tileContainUnexpectedCharacter = test(/"|'/, name);

  const isTagUsed = compose(
    not,
    isEmpty,
    prop('itemsIds')
  )(tag);

  if (isLoading || isLoadingColors || isLoadingOffer) return <Loading/>;

  return (
    <>
      {isNotNil(isOpenCreateGlobalVariableModal) && (
        <CreateGlobalVariableModal
          isOpenCreateGlobalVariableModal={isOpenCreateGlobalVariableModal}
          offerId={offerId}
          setGlobalVariableCreated={setGlobalVariableCreated}
        />
      )}
      <Title
        title={title}
        backTo={offerId ? `/offre/${offerId}/edition` : '/equipe/elements/tags'}
      />
      <Section title="nom du tag">
        <div className="flex gap-4">
          <div className="w-full">
            <Input
              className="h-50px"
              value={name}
              placeholder="Nom du tag"
              onChange={(e) => setState(assoc('name', e.target.value))}
              size="large"
              disabled={preview}
              status={tileContainUnexpectedCharacter && 'error'}
            />
            <p className="text-red-500 pt-1 h-6">{tileContainUnexpectedCharacter ? 'Les guillemets ne sont pas autorisés' : ''}</p>
          </div>

          {!isEdit && (
            <Select
              value={itemType}
              onChange={(v) => setState(assoc('itemType', v))}
              placeholder="Type de tag"
              className="w-300px"
              size="large"
              suffixIcon={<ChevronBottom />}
            >
              <Select.Option value="document">Document</Select.Option>
            </Select>
          )}
        </div>

        <div className="mt-8 ml-1">
          <label className="mb-2 block font-bold">Couleur</label>
          {tagExist ? (
            <div className="relative w-72">
              {showColorPicker && (
                <Components.ColorPickerBody
                  color={color || '#000'}
                  setColor={(v) => setState(assoc('color', v))}
                  handleColorChange={(v) => setState(assoc('color', v))}
                  setShowOptions={setShowColorPicker}
                  favoritesColors={favoritesColors}
                  isDraggable
                />
              )}
              {tagEditable ? (
                <div
                  className="flex items-center gap-2 cursor-pointer h-12 text-flexibranche-lightblue"
                  onClick={() => setShowColorPicker(!showColorPicker)}
                >
                  <ColorBlock color={color || '#000'} />
                </div>
              ) : <ColorBlock color={color || '#000'} />}
            </div>
          ) : (
            <div className="flex items-start">
              <Components.ColorPickerBody
                color={color || '#000'}
                setColor={(v) => setState(assoc('color', v))}
                handleColorChange={(v) => setState(assoc('color', v))}
                favoritesColors={[]}
              />
            </div>
          )}
        </div>
        {isEdit && (
          <TagItems
            preview={preview}
            itemType={itemType}
            itemsIds={itemsIds}
            onItemUp={onItemUp}
            onItemDown={onItemDown}
            rules={offerId && (rules || [])}
            userCanEditTag={userIsOfferValidator}
            getRulesAreValid={getRulesAreValid}
            getConditionsAreValid={getConditionsAreValid}
            variables={globalVariables}
            addTagRule={addTagRule}
            removeTagRule={removeTagRule}
            updateTagRule={updateTagRule}
            userIsAdmin={userIsAdmin}
            setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
            tag={tag}
          />
        )}
      </Section>

      <div className="flex flex-row justify-center items-center gap-x-3 mb-3">
        {!preview && isEdit && (
          <Button
            onClick={removeTag}
            disabled={isTagUsed}
            type="danger"
            size="large"
            icon={<DeleteOutlined />}
          >
            Supprimer
          </Button>
        )}
        {!preview && (
          <Button
            onClick={onSave}
            disabled={!isValid || !allRulesAreValid || tileContainUnexpectedCharacter}
            type="primary"
            size="large"
            icon={<ThunderboltOutlined />}
          >
            {isEdit ? 'Mettre à jour le tag' : 'Créer le tag'}
          </Button>
        )}
      </div>

      {isEdit && offerId && (
        <TagCurlSection
          bundleTag={name}
          offerId={offerId}
          teams={teams}
          userTeam={userTeam}
          offer={offer}
        />
      )}
    </>
  );
};

export default withUser(withOfferRights(TagForm));
